import {defineStore} from "pinia/dist/pinia";

export const useApplicationStore = defineStore('ApplicationStore', {
    state() {
        return {
            categoryState: null,
            baseCookiesState: localStorage.baseCookies === 'true',
            analyticsCookiesState: localStorage.analyticsCookies === 'true',
            marketingCookiesState: localStorage.marketingCookies === 'true',
            studyInformationState: {},
            scoringForm1State: {},
            scoringForm2aState: {},
            scoringForm2bState: {},
            scoringForm2cState: {},
            scoringForm3State: {},
            qolAdjustmentState: '-',
            adjustmentState: '-',
            adjustmentForm2aState: '-',
            adjustmentForm2bState: '-',
            adjustmentForm3State: '-'
        }
    },
    getters: {
        category(){
            return this.categoryState
        },
        studyInformation(){
            return this.studyInformationState
        },
        scoringForm1(){
            return this.scoringForm1State
        },
        scoringForm2a(){
            return this.scoringForm2aState
        },
        scoringForm2b(){
            return this.scoringForm2bState
        },
        scoringForm2c(){
            return this.scoringForm2cState
        },
        scoringForm3(){
            return this.scoringForm3State
        },
        qolAdjustment(){
            return this.qolAdjustmentState
        },
        adjustment(){
            return this.adjustmentState
        },
        adjustmentForm2a(){
            return this.adjustmentForm2aState
        },
        adjustmentForm2b(){
            return this.adjustmentForm2bState
        },
        adjustmentForm3(){
            return this.adjustmentForm3State
        },
        baseCookies() {
            return this.baseCookiesState
        },
        analyticsCookies() {
            return this.analyticsCookiesState
        },
        marketingCookies() {
            return this.marketingCookiesState
        },
    },
    actions: {
        setCategory(category){
            this.categoryState = category
        },
        setStudyInformation(studyInformation){
            this.studyInformationState = studyInformation
        },
        setScoringForm1(scoring){
            this.scoringForm1State = scoring
        },
        setScoringForm2a(scoring){
            this.scoringForm2aState = scoring
        },
        setScoringForm2b(scoring){
            this.scoringForm2bState = scoring
        },
        setScoringForm2c(scoring){
            this.scoringForm2cState = scoring
        },
        setScoringForm3(scoring){
            this.scoringForm3State = scoring
        },
        setQolAdjustment(qolAdjustment){
            this.qolAdjustmentState = qolAdjustment
        },
        setAdjustment(adjustment){
            this.adjustmentState = adjustment
        },
        setAdjustmentForm2a(adjustment){
            this.adjustmentForm2aState = adjustment
        },
        setAdjustmentForm2b(adjustment){
            this.adjustmentForm2bState = adjustment
        },
        setAdjustmentForm3(adjustment){
            this.adjustmentForm3State = adjustment
        },
        setBaseCookies(baseCookies) {
            this.baseCookiesState = baseCookies;
            localStorage.baseCookies = baseCookies;
        },
        setAnalyticsCookies(analyticsCookies) {
            this.analyticsCookiesState = analyticsCookies;
            localStorage.analyticsCookies = analyticsCookies;
        },
        setMarketingCookies(marketingCookies) {
            this.marketingCookiesState = marketingCookies;
            localStorage.marketingCookies = marketingCookies;
        }
    }
})
