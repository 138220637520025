<template>
  <Dialog v-model:visible="cookieDialogIsVisible" :closable="false" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '100%'}" position="bottom" :modal="true">
    <div class="grid" style="padding:1em">
      <div class="col-12 lg:col-6">
        {{ $t("footer.text3") }}<br>
        {{ $t("footer.text4") }}
        <a href="https://www.esmo.org/terms-of-use/privacy-policy" target="_blank">{{ $t("footer.privacy-policy") }}</a>.
      </div>
      <div class="col-12 lg:col-6" style="text-align: right;">
        <Button @click="rejectAllCookies()" class="neutral-300-bg cookie-buttons reject-all">{{ $t("cookies.reject-all") }}</Button>
        <Button @click="acceptAllCookies()" class="neutral-300-bg cookie-buttons accept-all">{{ $t("cookies.accept-all") }}</Button>
        <div @click="toggleCustomiseCookies()" style="text-decoration: underline; cursor: pointer;margin-top: 1em">
          {{ $t("cookies.customise") }}
        </div>
      </div>
      <div v-if="customiseCookiesIsVisible" class="col-12 grid" style="margin-top: 1em;margin-bottom: 1em;">
        <div class="col-12 md:col-4">
          <InputSwitch v-model="selectedBaseCookies"/>
          <p><b>{{ $t("cookies.baseCookiesTitle") }}</b></p>
          <p>{{ $t("cookies.baseCookiesBody") }}</p>
        </div>
        <div class="col-12 md:col-4">
          <InputSwitch v-model="selectedAnalyticsCookies"/>
          <p><b>{{ $t("cookies.analyticsCookiesTitle") }}</b></p>
          <p>{{ $t("cookies.analyticsCookiesBody") }}</p>
        </div>
        <div class="col-12 md:col-4">
          <InputSwitch v-model="selectedMarketingCookies"/>
          <p><b>{{ $t("cookies.marketingCookiesTitle") }}</b></p>
          <p>{{ $t("cookies.marketingCookiesBody") }}</p>
        </div>
        <div class="col-12">
          <Button @click="saveCookieSettings()" class="cookie-buttons neutral-300-bg" style="float: right">
            {{ $t("cookies.save") }}
          </Button>
        </div>
      </div>
    </div>
  </Dialog>
  <div class="pt-3 pb-3">

    <div class="col-12">
      <span class="text-sm-semi-bold purple-800-cl">Disclaimer:</span>
      <span class="text-sm-regular neutral-500"> Please note that the scores generated by this interactive tool have not undergone approval and validation by ESMO. All approved scores can be found in the
        <a class="purple-800-cl"
          href="https://www.esmo.org/guidelines/esmo-mcbs/esmo-mcbs-for-solid-tumours/esmo-mcbs-scorecards">ESMO-MCBS Scorecards</a>
      </span>
    </div>

    <div class="col-12">
      <span class="text-sm-semi-bold purple-800-cl">Terms of use:</span>
      <span class="text-sm-regular neutral-500"> Commercial institutions and organisations are prohibited from using or reproducing the ESMO-MCBS scores without written permission from ESMO. To enquire about permission, please send an email to <a
          class="purple-800-cl" href="mailto:publication_support@esmo.org">Publication Support</a>.</span>
    </div>

    <div class="pt-3 pl-3 pr-3">

      <div class="col-12 grid box-images">

        <div class="pt-3 pl-2 pr-2">
          <span class="text-xs-semi-bold neutral-black">Powered by</span>
        </div>

        <div class="pt-3 pl-2 pr-2">
          <img class="image" src="../public/images/Logo.png" alt="logo">
        </div>

        <div class="pt-3 pl-2 pr-2 ml-0 sm:ml-auto">
          <span class="text-xs-regular neutral-400">ESMO-Magnitude of Clinical Benefit Scale | </span>
          <span class="text-xs-regular neutral-400" style="text-decoration: underline;cursor:pointer;text-align: right" @click="showCookieDialog">Manage Cookies</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {useApplicationStore} from "@/store/applicationStore";

export default {
  beforeCreate() {
    this.applicationStore = useApplicationStore()
  },
  props: {
    styleClass: {
      type: String,
    },
  },
  data() {
    return {
      selectedBaseCookies: true,
      selectedAnalyticsCookies: false,
      selectedMarketingCookies: false,
      cookieDialogIsVisible: false,
      customiseCookiesIsVisible: false
    }
  },
  methods: {
    toggleCustomiseCookies() {
      this.customiseCookiesIsVisible = !this.customiseCookiesIsVisible
    },
    saveCookieSettings() {
      this.applicationStore.setBaseCookies(this.selectedBaseCookies)
      this.applicationStore.setAnalyticsCookies(this.selectedAnalyticsCookies)
      this.applicationStore.setMarketingCookies(this.selectedMarketingCookies)
      this.customiseCookiesIsVisible = false;
      this.hideCookieDialog()
    },
    acceptAllCookies() {
      this.selectedBaseCookies = true
      this.selectedAnalyticsCookies = true
      this.selectedMarketingCookies = true
      this.saveCookieSettings()
    },
    rejectAllCookies() {
      this.selectedBaseCookies = false
      this.selectedAnalyticsCookies = false
      this.selectedMarketingCookies = false
      this.saveCookieSettings()
    },
    showCookieDialog() {
      this.cookieDialogIsVisible = true;
    },
    hideCookieDialog() {
      this.cookieDialogIsVisible = false;
    },
    activateAnalytics() {
      if (this.analyticsCookies === true) {
        this.$gtm.enable(true)
      } else {
        this.$gtm.enable(false)
      }
    }
  },
  computed: {
    baseCookies() {
      return this.applicationStore.baseCookies
    },
    analyticsCookies() {
      return this.applicationStore.analyticsCookies
    },
    marketingCookies() {
      return this.applicationStore.marketingCookies
    }
  },
  mounted() {
    this.selectedAnalyticsCookies = this.analyticsCookies
    this.selectedMarketingCookies = this.marketingCookies
    if (!this.baseCookies === true) {
      this.showCookieDialog()
    }
    if (this.analyticsCookies === true) {
      this.activateAnalytics()
    }
  },
  watch: {
    analyticsCookies() {
      this.activateAnalytics()
    }
  }
}
</script>

<style scoped lang="scss">
.cookie-buttons {
  min-width: 10em;
  font-family: "HelveticaLTW05-BlackCond", Arial, Helvetica, sans-serif;
  padding: .8em;
  margin: .2em;
  border: none;
}

.box-images {
  border-top: 1px solid #9FA5A9;
}
</style>